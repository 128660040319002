*{
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Impact', fantasy;
  text-transform: uppercase;
}



